
import * as Yup from "yup";
import { defineComponent, onMounted, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { isNumber } from "@/core/helpers/generalHelper";
import ApiService from "@/core/services/ApiService";
import { useRouter } from "vue-router";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { errorMessage } from "@/global-helper";
export default defineComponent({
  name: "input-media",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  setup() {
    const submitButton1 = ref<HTMLElement | null>(null);
    const router = useRouter();
    const FormMedia = ref({
      nama: "",
      source: "",
      stasiun: "",
      tipe: "",
      cakupan: "",
      provinsi: "",
      bw: {
        lebar: 0,
        rate: 0,
      },
      fc: {
        lebar: 0,
        rate: 0,
      },
    });

    const FormMediaValidator = Yup.object().shape({
      source: Yup.string().required("Source harus diisi"),
      tipe: Yup.string().required("Tipe harus diisi"),
      cakupan: Yup.string().required("Cakupan harus diisi"),
      media: Yup.string().when("source", {
        is: (source) => source === "news" || source === "media_cetak",
        then: Yup.string()
          .required("Media harus diisi")
          .when("source", {
            is: "news",
            then: Yup.string()
              .required()
              .matches(
                /^(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+(?:[a-zA-Z]{2,})$/,
                "Nama media tidak sesuai"
              ),
          }),
        otherwise: Yup.string().notRequired(),
      }),
      program: Yup.string().when("source", {
        is: (val) => val === "media_tv",
        then: Yup.string().required("Program harus diisi"),
        otherwise: Yup.string().notRequired(),
      }),
      stasiun: Yup.string().when("source", {
        is: (val) => val === "media_tv",
        then: Yup.string().required("Stasiun TV harus diisi"),
        otherwise: Yup.string().notRequired(),
      }),
      lebar_bw: Yup.string().when("tipe", {
        is: (val) => val === "media_cetak",
        then: Yup.string().required("Lebar harus diisi"),
        otherwise: Yup.string().notRequired(),
      }),
      rate_bw: Yup.string().when("tipe", {
        is: (val) => val === "media_cetak",
        then: Yup.string().required("Rate harus diisi"),
        otherwise: Yup.string().notRequired(),
      }),
      lebar_fc: Yup.string().when("tipe", {
        is: (val) => val === "media_cetak",
        then: Yup.string().required("Lebar harus diisi"),
        otherwise: Yup.string().notRequired(),
      }),
      rate_fc: Yup.string().when("tipe", {
        is: (val) => val === "media_cetak",
        then: Yup.string().required("Rate harus diisi"),
        otherwise: Yup.string().notRequired(),
      }),
      provinsi: Yup.string().when("cakupan", {
        is: (val) => val === "nasional",
        then: Yup.string().required("Provinsi harus diisi"),
        otherwise: Yup.string().notRequired(),
      }),
    });

    const changeCakupan = () => {
      if (FormMedia.value.cakupan == "internasional") {
        FormMedia.value.provinsi = "-";
      }
    };

    const mediaNameFormatter = () => {
      if (FormMedia.value.source && FormMedia.value.source === "news") {
        FormMedia.value.nama = FormMedia.value.nama
          .toLocaleLowerCase()
          .replaceAll(/\s/g, "");
      }
    };

    const saveChanges1 = () => {
      if (submitButton1.value) {
        // Activate indicator
        submitButton1.value.setAttribute("data-kt-indicator", "on");
        let loadout = FormMedia.value;
        ApiService.post("/media/save", { data: loadout })
          .then((response) => {
            if (response.status == 200) {
              Swal.fire({
                text: "Berhasil!",
                icon: "success",
                confirmButtonText: "Ok",
                buttonsStyling: false,
                customClass: {
                  confirmButton: "btn btn-light-primary",
                },
              }).then(() => {
                router.push({ name: "medias" });
              });
            } else {
              Swal.fire({
                text: "Gagal Menyimpan!",
                icon: "error",
                confirmButtonText: "Ok",
                buttonsStyling: false,
                customClass: {
                  confirmButton: "btn btn-light-primary",
                },
              });
            }
          })
          .catch((err) => {
            const message = errorMessage(err);
            Swal.fire({
              text: `Gagal! ${message}`,
              icon: "error",
              confirmButtonText: "Ok",
              buttonsStyling: false,
              customClass: {
                confirmButton: "btn btn-light-primary",
              },
            });
          })
          .finally(() => {
            // Deactivate indicator
            submitButton1.value?.removeAttribute("data-kt-indicator");
          });
      }
    };
    onMounted(() => {
      setCurrentPageBreadcrumbs("Input Media", ["Media"]);
    });
    return {
      FormMedia,
      FormMediaValidator,
      saveChanges1,
      isNumber,
      submitButton1,
      changeCakupan,
      mediaNameFormatter,
    };
  },
});
